<template>
  <default-layout>
    <v-container id="listing-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <listing-form :readOnly="true" />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const ListingForm = () => import('@/components/data-entry/listing/form/listing-form.vue');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    ListingForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      listingPermissions: (state) => state.listing.permissions,
      detail: (state) => state.listing.detail,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let promises = [];
      let query = this.$route.query;
      query.listing_uuid = this.$route.params.uuid;

      this.$store.commit('listing/RESET_DEFAULT');
      promises.push(this.$store.dispatch('listing/getDetailInitData', this.$route.params.uuid));

      const response = await Promise.all(promises);
      console.log('DETAIL LISTING: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {},
};
</script>
